import React from "react";
import Slider from "react-slick";
import { Slide, Fade } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settingsrecent } from "../reusables/carouselrecent";
import s1 from "../../assets/images/staffparty2024/1.jpg";
import s2 from "../../assets/images/staffparty2024/2.jpg";
import s3 from "../../assets/images/staffparty2024/3.jpg";
import s4 from "../../assets/images/staffparty2024/4.jpg";
import s5 from "../../assets/images/staffparty2024/5.jpg";
import s6 from "../../assets/images/staffparty2024/6.jpg";
import s7 from "../../assets/images/staffparty2024/7.jpg";
import s8 from "../../assets/images/staffparty2024/8.jpg";
import s9 from "../../assets/images/staffparty2024/9.jpg";
import s10 from "../../assets/images/staffparty2024/10.jpg";
import GalleryCard from "../reusables/GalleryCard";

const RecentEvents = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center">
                  <h3 className="text-shadow green">Recent Events in Photos</h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    As it happened...
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-50">
                <div className="slider-items-container gallery-container">
                  <Slider {...settingsrecent}>
                    <GalleryCard
                      galleryImage={s1}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s2}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s3}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s4}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s5}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s6}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s7}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s8}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s9}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s10}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                  </Slider>
                </div>
              </div>
              <div className="section-footer center section-spacing-100 no-padding-top">
                <div className=" section-spacing-20">
                  <a
                    href="https://www.instagram.com/kampala_city_grammar_school/profilecard/?igsh=MWE4bTR0djYxMGhjYw=="
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button green-bg white"
                  >
                    See More Photos
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentEvents;
